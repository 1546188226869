import { SET_COMPONENT_SELECTORS } from "../actions/sync/componentSelectors"

const initialState = {
    componentFuelTypeList: [],
    componentGearTypeList: [],
    componentTurboTypeList: [],
    componentEngineBuildList: [],
    componentCoolTypeList: [],
    componentFillVolumeTypeList: [
      {
        hidden: false,
        code: '1',
        name: 'мл',
      },
      {
        hidden: false,
        code: '2',
        name: 'л',
      }
    ],
    componentFillServiceVolumeTypeList: [
      {
        hidden: false,
        code: '1',
        name: 'мл',
      },
      {
        hidden: false,
        code: '2',
        name: 'л',
      }
    ],
}

function componentSelectors(state = initialState, action) {
  switch (action.type) {
    case SET_COMPONENT_SELECTORS:
      return {...state, ...action.payload }
    default:
      return state
  }
}

export default componentSelectors;