import { getProduct } from "../../redux/actions/async/product";
import { getHTTPData } from "../../utils/getData";

export const API = {
  getProducts: function (body) {
    return getHTTPData("api/v1/product/filter/page", "POST", body);
  },
  getModelsByProductAndMark: function (body) {
    return getHTTPData("api/v1/search-by-product/models/filter", "POST", body);
  },
  getModelGenerationsByProductAndModel: function (body) {
    return getHTTPData(
      "api/v1/search-by-product/model-generations/filter",
      "POST",
      body
    );
  },
  getModelComplectsByProductAndGeneration: function (body) {
    return getHTTPData(
      "api/v1/search-by-product/model-complects/filter",
      "POST",
      body
    );
  },
  getMarksByProduct: function (body) {
    return getHTTPData("api/v1/search-by-product/marks/filter", "POST", body);
  },
  getComponentsByProductAndModelComplect: function (body) {
    return getHTTPData(
      "api/v1/search-by-product/components/filter",
      "POST",
      body
    );
  },
  getCategories: function (body) {
    return getHTTPData("api/v1/model-category/filter", "POST", body);
  },
  getSubcategories: function (body) {
    return getHTTPData("api/v1/model-subcategory/filter", "POST", body);
  },
  getMarks: function (body) {
    return getHTTPData("api/v1/model-mark/filter", "POST", body);
  },
  getModel: function (body) {
    return getHTTPData("api/v1/model-generation/filter ", "POST", body);
  },
};
