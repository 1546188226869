import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Waiter } from "../../components/waiter";
import DropdownMultiSelect from "../../pureComponents/dropdownMultiSelect";
import { StatisticTable } from "./statisticTable";

const StatisticCoverReportForm = ({
  formErrors,
  onDelete,
  onSearch,
  onSelect,
  onClearField,
  onSelectAll,
  processedList,
  handleButtonClick,
  onExelToPageButtonClick,
  tableData,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const [selectedModification, setSelectedModification] = useState(null);
  const formData = useSelector((state) => state.statisticCoverReportForm);
  const { specificationList, viscoList, catigoryList } = useSelector(
    (state) => state.statisticCoverReportFormSelectors
  );

  const handleNavigateToModelComplect = () => {
    const code = selectedModification.toString();
    navigate("/model-complect/" + code, {
      state: { actionType: "edit", componentCode: code },
    });
    return;
  };

  if (formData.fetchingStatus === "loading") {
    return <Waiter size="large" />;
  } else {
    return (
      <form onSubmit={onSubmit}>
        <fieldset className="model-complect-general__form" disabled={false}>
          <div className="model-complect-general__header">
            <div className="main-header-block">
              <div className="main-header-block__item main-header-block__title">
                Статистика - покрытие спецификаций техники
              </div>
            </div>
          </div>
          <div className="model-complect-general__header model-complect-general__buttons">
            <button
              type="button"
              className="button button__secondary button__secondary__ghost button__buttons-wrapper"
              onClick={handleButtonClick}
            >
              Сбросить настройки
            </button>
            <button
              type="button"
              className="button button__primary button__buttons-wrapper"
              onClick={handleNavigateToModelComplect}
              disabled={!selectedModification}
            >
              Редактировать
            </button>
            <button
              type="button buttons-wrapper"
              className="button button__primary"
              onClick={onExelToPageButtonClick}
            >
              Сформировать отчет
            </button>
            <button
              type="submit"
              className="button button__primary button__buttons-wrapper"
              disabled={!tableData}
            >
              Выгрузить отчет
            </button>
          </div>
          <div>
            <div className="main-form-block statisticRequestReportFormWrapper">
              <div className="main-form-block__column statisticRequestReportFormWrapper__column">
                <div className="main-form-block__row">
                  <DropdownMultiSelect
                    id="selectedSpecification"
                    label="Спецификация"
                    items={specificationList}
                    selectedItems={formData.selectedSpecification}
                    onDelete={onDelete}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    onSelectAll={onSelectAll}
                    onClearField={onClearField}
                    isLoading={processedList.selectedSpecification}
                    isSearchable={true}
                    isSelectAllAvailable={true}
                    required={true}
                    error={formErrors.selectedSpecification}
                  />
                </div>
                <div className="main-form-block__row">
                  <DropdownMultiSelect
                    id="selectedVisco"
                    label="Вязкости"
                    items={viscoList}
                    selectedItems={formData.selectedVisco}
                    onDelete={onDelete}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    onSelectAll={onSelectAll}
                    onClearField={onClearField}
                    isLoading={processedList.selectedVisco}
                    isSearchable={true}
                    isSelectAllAvailable={true}
                  />
                </div>
                <div className="main-form-block__row">
                  <DropdownMultiSelect
                    id="selectedCategory"
                    label="Категория техники"
                    items={catigoryList}
                    selectedItems={formData.selectedCategory}
                    onDelete={onDelete}
                    onSelect={onSelect}
                    onSelectAll={onSelectAll}
                    onClearField={onClearField}
                    isLoading={processedList.selectedCategory}
                    isSelectAllAvailable={true}
                  />
                </div>
              </div>
              {tableData && tableData?.marks && !!tableData?.marks.length && (
                <div>
                  <StatisticTable
                    data={tableData}
                    setSelectedModification={setSelectedModification}
                  />
                </div>
              )}

              <div className="container-block__content">
                {tableData && !tableData?.marks.length && (
                  <div className="container-table-empty">Нет данных</div>
                )}
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    );
  }
};

export default StatisticCoverReportForm;
