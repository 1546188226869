import React, { useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import VerticalLine from "../../../components/veritcalLine";
import CodeInputField from "../../../pureComponents/codeInputField";
import ModalLinkWrapper from "../../../components/modalLinkWrapper";
import FormInputField from "../../../pureComponents/formInputField";
import DropdownSelect from "../../../pureComponents/dropdownSelect";
import ToggleSwitch from "../../../pureComponents/toggleSwitch";
import { copyComponent } from "../../../redux/actions/async/component";
import { getModelComplectMenu } from "../../../redux/actions/async/modelComplectMenu";

import "./index.scss";
import { TextAreaDescription } from "../../../components/textAreaDescription";
import { Waiter } from "../../../components/waiter";
import getNameFromList from "../../../utils/getNameFromList";
import generateModelComplectLink from "../../../utils/generateModelComplectLink";
import YearPicker from "../../../pureComponents/yearPicker";
import { SelectModificationModal } from "../../../pureComponents/modal/SelectModificationModal";

const ComponentForm = ({
  formErrors,
  actionType,
  modelComplectCode,
  getNewCode,
  searchLoading,
  handleDateChange,
  handleDropdownSearch,
  handleInputChange,
  handleDropdownChange,
  handleDeleteComponent,
  openHistoryChanges,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentData = useSelector((state) => state.component);
  const componentSelectors = useSelector((state) => state.componentSelectors);
  const { componentGroupList, componentTypeList, producerList } = useSelector(
    (state) => ({
      componentGroupList: state.componentGroup.list,
      componentTypeList: state.componentType.list,
      producerList: state.producer.list,
    })
  );

  const searchStage = useSelector(state => state.search.stage);
  const isBackToListLinkType = searchStage !== "product"
  const backToProductLink = `/search?code=${modelComplectCode}`

  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);
  const [defaultValues, setDefaultValues] = useState({});
  const [selectModificationModalActive, setSelectModificationModalActive] = useState(false);
	const [selectedModification, setSelectedModification] = useState(null);

  useLayoutEffect(() => {
    if (componentData) {
      setDefaultValues(() => ({
        componentGroup: getNameFromList(
          componentGroupList,
          componentData.componentGroupCode
        ),
        componentType: getNameFromList(
          componentTypeList,
          componentData.componentTypeCode
        ),
        producer: getNameFromList(producerList, componentData.producerCode),
        componentFuelType: getNameFromList(
          componentSelectors.componentFuelTypeList,
          componentData.componentFuelTypeCode
        ),
        componentGearType: getNameFromList(
          componentSelectors.componentGearTypeList,
          componentData.componentGearTypeCode
        ),
        componentTurboType: getNameFromList(
          componentSelectors.componentTurboTypeList,
          componentData.componentTurboTypeCode
        ),
        componentEngineBuild: getNameFromList(
          componentSelectors.componentEngineBuildList,
          componentData.componentEngineBuildCode
        ),
        componentCoolType: getNameFromList(
          componentSelectors.componentCoolTypeList,
          componentData.componentCoolTypeCode
        ),
        componentFillVolumeType: getNameFromList(
          componentSelectors.componentFillVolumeTypeList,
          componentData.fillVolumeType
        ),
        componentFillServiceVolumeType: getNameFromList(
          componentSelectors.componentFillServiceVolumeTypeList,
          componentData.fillServiceVolumeType
        )
      }));
    }
  }, [componentData]);

  if (componentData.status === "loading") {
    return <Waiter size="large" />;
  }




  const handleOpenCopyModal = async () => { 
    setSelectModificationModalActive(true)
  }
    

  const handleCopyComponentToCurrentModification = async () => { 
   const newComponentCode =   await dispatch(
        copyComponent([componentData.code],  componentData.modelComplectCode)
      );
  await dispatch(getModelComplectMenu( componentData.modelComplectCode));
    const url = `/model-complect/${componentData.modelComplectCode}/component/${newComponentCode}`
      navigate(url, {
        state: { actionType: "edit", componentCode: newComponentCode },
      })
  }
  


  const handleCopyComponentToOtherModification = async () => { 
    if(selectedModification?.code && componentData?.code ) {
     await dispatch(
        copyComponent([componentData.code],  selectedModification.code)
      );
  }
  setSelectModificationModalActive(false) 
  }  

  return (
    <>
    <form onSubmit={onSubmit}>
      <fieldset
        className="component-general__form"
        disabled={componentData.status === "update"}
      >
        <div className="component-general__header">
          <div className="main-header-block">
            <div className="main-header-block__item main-header-block__title">
              Данные об узле
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={actionType !== "edit"}
              onClick={handleDeleteComponent}
            >
              <div
                className={`control__img control__img_trash${
                  actionType !== "edit" ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              onClick={openHistoryChanges}
              disabled={actionType !== "edit"}
            >
              <div
                className={`control__img control__img_history${
                  actionType !== "edit" ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <div className="main-header-block__item main-header-block__code input-block input-block--flex">
              <CodeInputField
                id="code"
                label="Код"
                placeholder="Введите"
                value={componentData.code}
                error={formErrors.code}
                onChange={handleInputChange}
                required={true}
                disabled={actionType === "edit"}
              />
              <button
                type="button"
                className="button button__small button__primary"
                onClick={() => getNewCode()}
                disabled={actionType === "edit"}
              >
                Создать код
              </button>
            </div>
            <VerticalLine size="24px" />
          </div>
          <div className="additional-header-block">
            <ModalLinkWrapper
              linkProps={{
                to: generateModelComplectLink(modelComplectCode, {
                  type: "condition",
                  code: "new",
                }),
                state: { actionType: "new", componentCode: componentData.code },
              }}
              className="main-header-block__item button button__small button__primary"
              disabled={actionType !== "edit"}
            >
              Добавить условие
            </ModalLinkWrapper>

            {actionType === "edit" &&
            <>
            <div
                className="main-header-block__item button button__primary button__small"
                onClick={handleCopyComponentToCurrentModification}
                title="Копировать узел в текущую модификацию"   
              >
              <div className="control__img copy-icon"></div>
            </div>
            <div
                className="main-header-block__item button button__primary button__small"
                onClick={handleOpenCopyModal}
                title="Копировать узел в другую модификацию"
                
              >
               <div className="control__img add-to-icon" ></div>
          </div>  
          </>
     }

          </div>
        </div>
        <div className="main-form-block">
          <div className="main-form-block__column">
            <div className="main-form-block__column__block">
              <FormInputField
                label="Название"
                id="name"
                value={componentData.name || ""}
                error={formErrors.name}
                onChange={handleInputChange}
              />
              <DropdownSelect
                label="Группа узла"
                id="componentGroupCode"
                items={componentGroupList}
                defaultValue={defaultValues.componentGroup}
                error={formErrors.componentGroupCode}
                onSelect={handleDropdownChange}
                required
              />
              <DropdownSelect
                label="Тип узла"
                id="componentTypeCode"
                items={componentTypeList}
                defaultValue={defaultValues.componentType}
                error={formErrors.componentTypeCode}
                onSelect={handleDropdownChange}
                searchable={true}
                onSearch={handleDropdownSearch}
                isLoading={searchLoading.componentTypeCode}
                required
                disabled={!componentData.componentGroupCode}
              />
              <DropdownSelect
                label="Производитель"
                id="producerCode"
                items={producerList}
                defaultValue={defaultValues.producer}
                searchable={true}
                onSearch={handleDropdownSearch}
                isLoading={searchLoading.producerCode}
                onSelect={handleDropdownChange}
              />
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <div className="main-form-block__row">
                <YearPicker
                  id="startYear"
                  label="Год (начало)"
                  placeholder="Выберите"
                  value={componentData.startYear}
                  onChangeData={handleDateChange}
                  maxDate={componentData.endYear}
                />
                <YearPicker
                  id="endYear"
                  label="Год (окончание)"
                  placeholder="Выберите"
                  value={componentData.endYear}
                  onChangeData={handleDateChange}
                  minDate={componentData.startYear}
                />
              </div>
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <div className="input-wrapper-row">
                <div className="middle-width">
                  <FormInputField
                    label={
                      <>
                        Объем заливки полный
                      </>
                    }
                    id="fillVolume"
                    inputMask="positive-integer"
                    value={componentData.fillVolume || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="dropdownSelect">
                  <DropdownSelect
                    id="fillVolumeType"
                    items={componentSelectors.componentFillVolumeTypeList}
                    defaultValue={defaultValues.componentFillVolumeType}
                    onSelect={handleDropdownChange}
                    required
                  />
                </div>                
              </div>
              <div className="input-wrapper-row">
                <div className="middle-width">
                  <FormInputField
                    label={
                      <>
                        Объем заливки сервисный
                      </>
                    }
                    id="serviceVolume"
                    inputMask="positive-integer"
                    value={componentData.serviceVolume || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="dropdownSelect">
                  <DropdownSelect
                    id="fillServiceVolumeType"
                    items={componentSelectors.componentFillServiceVolumeTypeList}
                    defaultValue={defaultValues.componentFillServiceVolumeType}
                    onSelect={handleDropdownChange}
                    required
                  />
                </div>                
              </div>
              <TextAreaDescription
                label="Описание"
                id="description"
                value={componentData.description || ""}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="main-form-block__column">
            <div className="main-form-block__column__block">
              <DropdownSelect
                label="Тип топлива"
                id="componentFuelTypeCode"
                items={componentSelectors.componentFuelTypeList}
                defaultValue={defaultValues.componentFuelType}
                onSelect={handleDropdownChange}
              />
              <DropdownSelect
                label="Тип коробки передач"
                id="componentGearTypeCode"
                items={componentSelectors.componentGearTypeList}
                defaultValue={defaultValues.componentGearType}
                onSelect={handleDropdownChange}
              />
              <FormInputField
                label={
                  <>
                    Объем двигателя см<sup>3</sup>
                  </>
                }
                id="engineCap"
                inputMask="positive-integer"
                value={componentData.engineCap || ""}
                onChange={handleInputChange}
              />
              <div className="main-form-block__row">
                <FormInputField
                  label="Мощность двигателя (л.с)"
                  id="powerHp"
                  inputMask="positive-integer"
                  value={componentData.powerHp || ""}
                  onChange={handleInputChange}
                />
                <FormInputField
                  label={<>Обороты максимальной мощности (об/мин)</>}
                  id="powerRpm"
                  inputMask="positive-integer"
                  value={componentData.powerRpm || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <DropdownSelect
                label="Тип турбины"
                id="componentTurboTypeCode"
                items={componentSelectors.componentTurboTypeList}
                defaultValue={defaultValues.componentTurboType}
                onSelect={handleDropdownChange}
              />
              <DropdownSelect
                label="Тип конструкции двигателя"
                id="componentEngineBuildCode"
                items={componentSelectors.componentEngineBuildList}
                defaultValue={defaultValues.componentEngineBuild}
                onSelect={handleDropdownChange}
              />
              <DropdownSelect
                label="Тип охлаждения"
                id="componentCoolTypeCode"
                items={componentSelectors.componentCoolTypeList}
                defaultValue={defaultValues.componentCoolType}
                onSelect={handleDropdownChange}
              />
              <div className="main-form-block__row">
                <FormInputField
                  label="Кол-во клапанов"
                  id="valveCnt"
                  inputMask="positive-integer"
                  value={componentData.valveCnt || ""}
                  onChange={handleInputChange}
                />
                <FormInputField
                  label="Кол-во тактов"
                  id="stroke"
                  inputMask="positive-integer"
                  value={componentData.stroke || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="main-form-block__row">
                <FormInputField
                  label="Кол-во цилиндров"
                  id="cilinderCnt"
                  inputMask="positive-integer"
                  value={componentData.cilinderCnt || ""}
                  onChange={handleInputChange}
                />
                <FormInputField
                  label="Объем цилиндра"
                  id="cilinderCap"
                  inputMask="positive-integer"
                  value={componentData.cilinderCap || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="main-form-block__row">
                <FormInputField
                  label="Кол-во передних передач"
                  id="gearFwCnt"
                  inputMask="positive-integer"
                  value={componentData.gearFwCnt || ""}
                  onChange={handleInputChange}
                />
                <FormInputField
                  label="Кол-во задних передач"
                  id="gearRevCnt"
                  inputMask="positive-integer"
                  value={componentData.gearRevCnt || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="component-general__footer">
          <div className="main-footer-block__toggles">
            <ToggleSwitch
              label="Скрыть"
              id="hidden"
              checked={componentData.hidden}
              onToggle={handleInputChange}
            />
          </div>
          <div className="main-footer-block__buttons">
          {isBackToListLinkType ?
          <ModalLinkWrapper
          linkProps={{ to: "/guides", state: { pageCode: 16, withoutReset: true } }}
          className="button button__secondary button__secondary__ghost"
            >
             Вернуться к списку ТС
            </ModalLinkWrapper>
            : 
            <ModalLinkWrapper
              linkProps={{ to: backToProductLink}}
              className="button button__secondary button__secondary__ghost"
            >
              Вернуться к ТС
            </ModalLinkWrapper>}
            <button
              type="submit"
              className="button button__primary"
              disabled={!dataChanged}
            >
              Сохранить изменения
            </button>
          </div>
        </div>
      </fieldset>
    </form>
      <SelectModificationModal   
      active={selectModificationModalActive}
      closeModal={() => setSelectModificationModalActive(false)}
      okModal={handleCopyComponentToOtherModification}
      selectedModification={selectedModification}
      setSelectedModification={setSelectedModification}
      />
   </>
  );
};

export default ComponentForm;
