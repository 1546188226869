import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import StatisticCoverReportForm from "./statisticCoverReportForm";
import useDownloadFile from "../../hooks/useDownloadFile";
import {
  getCategiry,
  getSpecifications,
  getVisco,
  makeStatisticCoverReport,
  makeExelToPageStatisticCoverReport,
} from "../../redux/actions/async/statisticCoverReportFormSelectors";
import {
  resetStatisticCoverReportFormState,
  updateStatisticCoverReportForm,
} from "../../redux/reducers/statisticCoverReportForm";
import { setStatisticCoverReportFormSelectors } from "../../redux/reducers/statisticCoverReportFormSelectors";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import "./statisticCoverReportPage.scss";

const StatisticCoverReportPage = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.statisticCoverReportForm);
  const [formErrors, setFormErrors] = useState({});
  const [tableData, setTableData] = useState(null);
  const [processedList, setProcessedList] = useState({});

  useDocumentTitle("Отчеты - Покрытие спецификацией техники");
  useEffect(() => {
    dispatch(getSpecifications());
    return () => {
      dispatch(setStatisticCoverReportFormSelectors());
    };
  }, []);

  useEffect(() => {
    dispatch(getVisco());
    setFormErrors((prev) => ({ ...prev, selectedSpecification: null }));
  }, [formData.selectedSpecification]);

  useEffect(() => {
    dispatch(getCategiry());
  }, [formData.selectedVisco]);

  const handleButtonClick = useCallback((items, id) => {
    dispatch(resetStatisticCoverReportFormState());
  }, []);

  const downloadFile = useDownloadFile();
  const onSubmit = (event) => {
    event.preventDefault();
    let hasError = false;

    if (!formData.selectedSpecification.length > 0) {
      setFormErrors((prev) => ({
        ...prev,
        selectedSpecification: "Значение обязательное",
      }));
      hasError = true;
    }
    if (!hasError) {
      dispatch(
        makeStatisticCoverReport((file, name) => downloadFile(file, name))
      );
    }
  };
  const onExelToPageButtonClick = (event) => {
    event.preventDefault();
    let hasError = false;

    if (!formData.selectedSpecification.length > 0) {
      setFormErrors((prev) => ({
        ...prev,
        selectedSpecification: "Значение обязательное",
      }));
      hasError = true;
    }
    if (!hasError) {
      dispatch(
        makeExelToPageStatisticCoverReport((data) => setTableData(data))
      );
    }
  };
  const onDelete = (code, id) => {
    const newSelectedItems = formData[id].filter(
      (selectedItem) => selectedItem.code !== code
    );
    dispatch(updateStatisticCoverReportForm({ [id]: newSelectedItems }));
  };
  const onSearch = (searchString, id) => {
    setProcessedList((prev) => ({ ...prev, [id]: true }));
    switch (id) {
      case "selectedSpecification":
        dispatch(
          getSpecifications(searchString, () =>
            setProcessedList((prev) => ({ ...prev, [id]: false }))
          )
        );
        break;
      case "selectedVisco":
        dispatch(
          getVisco(searchString, () =>
            setProcessedList((prev) => ({ ...prev, [id]: false }))
          )
        );
        break;
      default:
        break;
    }
  };
  const onSelect = (item, id) => {
    const isExist = formData[id].some((i) => i.code === item.code);
    if (!isExist) {
      const newSelectedItems = [...formData[id], item];
      dispatch(updateStatisticCoverReportForm({ [id]: newSelectedItems }));
    } else {
      onDelete(item.code, id);
    }
  };
  const onClearField = (id) => {
    dispatch(updateStatisticCoverReportForm({ [id]: [] }));
  };
  const onSelectAll = (items, id) => {
    const isAllAdded = formData[id].length === items.length;
    if (!isAllAdded) {
      dispatch(updateStatisticCoverReportForm({ [id]: items }));
    } else {
      onClearField(id);
    }
  };
  return (
    <StatisticCoverReportForm
      formErrors={formErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      onSearch={onSearch}
      onSelect={onSelect}
      onClearField={onClearField}
      onSelectAll={onSelectAll}
      processedList={processedList}
      handleButtonClick={handleButtonClick}
      tableData={tableData}
      onExelToPageButtonClick={onExelToPageButtonClick}
    />
  );
};

export default StatisticCoverReportPage;
