import React, { useState, useEffect, useMemo } from "react";
import { Waiter } from "../../components/waiter";

import { API } from "./api";
import DropdownSelect from "../../pureComponents/dropdownSelect";
import getNameFromList from "../../utils/getNameFromList";
import { MarkTreeSearch } from "./searchMarkTree";

export const ProductSearch = ({
  lang,
  products,
  changeDropdown,
  productCode,
}) => {
  const [productSearchString, setProductSearchString] = useState("");
  const [marksForProduct, setMarksForProduct] = useState([]);
  const [loadingMarks, setLoadingMarks] = useState(false);

  const handleProductSearch = async (id, searchString) => {
    setProductSearchString(searchString);
  };

  const getProducts = () => {
    if (!productSearchString) {
      return products;
    } else {
      return products.filter((item) =>
        item.name.toLowerCase().includes(productSearchString)
      );
    }
  };

  const productItems = useMemo(
    () => getProducts(),
    [products, productSearchString]
  );

  useEffect(() => {
    if (productCode) {
      setLoadingMarks(true);
      API.getMarksByProduct({
        lang: lang,
        productCode: productCode,
      }).then((response) => {
        setMarksForProduct(response);
        setLoadingMarks(false);
      });
    }
  }, [productCode]);

  return (
    <div className={"keywords-search"}>
      <div className={"productSearchTree"}>
        <div className="keywords-search__form">
          <DropdownSelect
            id="productCode"
            label="Продукт"
            defaultValue={getNameFromList(productItems, productCode)}
            items={productItems}
            value={productCode}
            onSelect={changeDropdown}
            required={true}
            searchable
            onSearch={handleProductSearch}
          />
        </div>

        <div>
          {loadingMarks && <Waiter size="large" />}

          {!loadingMarks && productCode && (
            <>
              {!marksForProduct.length && (
                <div className="search-result-table__no-data">
                  Марки для выбранного продукта не найдены
                </div>
              )}
              {!!marksForProduct.length && (
                <MarkTreeSearch
                  productCode={productCode}
                  marksForProduct={marksForProduct}
                  lang={lang}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
