import { Waiter } from "../../components/waiter";
import { Link } from "react-router-dom";
import { InfoRow } from "../../components/infoRow";
import VisibleComponentsList from "../../redux/containers/visibleComponentsList";
import useDownloadFile from "../../hooks/useDownloadFile";
import { makeModelComplectReport } from "../../redux/actions/async/productSearchReport";
import { useDispatch } from "react-redux";

const SearchViewProductForm = ({ state }) => {
  const dispatch = useDispatch();
  const downloadFile = useDownloadFile();

  if (state.fetching) {
    return <Waiter size="large" />;
  }

  const data = state;
  const productName =
    data.modelMark +
    " " +
    data.model +
    " " +
    data.modelGeneration +
    " " +
    data.modelComplect;
  const infoMass = [
    {
      name: "",
      value: (data.startYear ?? "") + " - " + (data.endYear ?? ""),
    },
    {
      name: "Тип привода",
      value: data.modelDrive,
    },
    { name: "Тип кузова", value: data.modelBody },
    { name: "Класс Евро", value: data.modelClass },
  ];

  const handleDownload = () => {
    if (data.code) {
      const name = `${productName}_${new Date().toLocaleString()}.xlsx`;
      dispatch(
        makeModelComplectReport(data.code, (file) => downloadFile(file, name))
      );
    }
  };

  return (
    <div className="selected-result">
      <div className="selected-result__content">
        <div className={"product-header" + (data.hidden ? " hidden" : "")}>
          <div className={"product-header__caption"}>{productName}</div>
          <div className={"product-header__buttons"}>
            <Link
              to={`/model-complect/${data.code}`}
              state={{ actionType: "edit" }}
              className="button button__primary"
            >
              Редактировать
            </Link>
            <button
              className={"button button__primary  button-wrapper"}
              disabled={false}
              onClick={handleDownload}
            >
              Выгрузить в файл
            </button>
          </div>
        </div>
        <InfoRow data={infoMass} />
        <VisibleComponentsList />
      </div>
    </div>
  );
};

export default SearchViewProductForm;
