import { CHANGE_LANGUAGE } from '../actions/sync/i18';
import { RESET_APP } from '../actions/sync/init';

const defState = {
  current: 'RU',
  list: ['RU', 'EN'],
  phrases: {
    authForm: {
      header: {
        RU: 'Добро пожаловать на портал',
        EN: 'Welcome to portal',
      },
      formHeader: {
        RU: 'Авторизация',
        EN: 'Autorization',
      },
      loginName: {
        RU: 'Логин',
        EN: 'Login',
      },
      loginPlaceholder: {
        RU: 'Введите ваш логин',
        EN: 'Type your login',
      },
      loginError: {
        absent_at: {
          RU: "Поле должно содержать символ '@'",
          EN: "The field must contain the '@' character",
        },
        too_long: {
          RU: 'Превышена допустимая длина поля',
          EN: 'The allowed field length has been exceeded',
        },
      },
      passwordName: {
        RU: 'Пароль',
        EN: 'Password',
      },
      passwordPlaceholder: {
        RU: 'Введите ваш пароль',
        EN: 'Type your password',
      },
      passwordError: {
        none: {
          RU: '',
          EN: '',
        },
        wrongData: {
          RU: 'Неверное имя пользователя/пароль',
          EN: 'Invalid username/password',
        },
        systemError: {
          RU: 'Произошла системная ошибка.',
          EN: 'A system error has occurred.',
        },
        accessError: {
          RU: 'Недостаточно прав.',
          EN: 'Not enough rights.',
        },
      },
      buttonCaption: {
        RU: 'Войти',
        EN: 'Sign in',
      },
      cancelButtonCaption: {
        RU: 'Отменить',
        EN: 'Cancel',
      },
      footerCaption_1: {
        RU: 'Нажимая на кнопку "Войти" вы принимаете',
        EN: 'Pressed to "Sign in" you accepted',
      },
      footerCaption_2: {
        RU: 'Пользовательское соглашение',
        EN: 'User agreement',
      },
    },

    selectorList: {
      header: {
        RU: 'Выберите категорию транспортных средств',
        EN: 'Select a vehicle category',
      },
      productHeader: {
        RU: 'Выберите продукт',
        EN: 'Select a product',
      },
      searchHeader: {
        RU: 'Отфильтровать список результатов',
        EN: 'Filter result list',
      },
    },

    pages: {
      product: { RU: 'подобрать продукт', EN: 'select product' },
      'model-complect': { RU: 'техника', EN: 'vehicle' },
      guides: { RU: 'все справочники', EN: 'guides' },
      components: {RU:'узлы',EN:'components'},
      products: {RU:'продукты',EN:'products'},
      specs: {RU:'спецификации',EN:'specifications'},
      reports: { RU: 'отчеты', EN: 'reports' },
    },

    dropdown: {
      placeholder: { RU: 'Не выбрано', EN: 'Not chosen' },
      noItems: { RU: 'Нет элементов', EN: 'No items' },
    },

    userInfo:{
      role:{
        expert:{
          RU: 'Эксперт',
          EN: 'Expert',
        } ,
        user:{
          RU: 'Пользователь',
          EN: 'User'
        }
      }
    },

    noData:{
      guides:{
        RU:'справочник',
        EN:'guide',
      }
    },

    guides: {
      controlPanel:{
        hiddenModeCaption:{
          true:{
            RU:"Убрать скрытое",
            EN:"Hide hiden",
          },
          false:{
            RU:"Показать скрытое",
            EN:"Show hidden",
          },
        }
      }
    }
  },
};

function i18(state = defState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return Object.assign({}, state, { current: action.lang });
    case RESET_APP:
      return defState;
    default:
      return state;
  }
}

export default i18;
