import React, { useState, useMemo } from "react";
import { Waiter } from "../../components/waiter";
import Checkbox from "../../pureComponents/checkbox";

const SearchResultForm = ({
  props,
  selectedModels,
  setSelectedModels,
  onLoadMore,
  activateProduct,
}) => {
  const allModelIds = useMemo(
    () => props.result.map((row) => row.modelComplectCode),
    [props]
  );

  const isCheckedAll = useMemo(
    () =>
      !!selectedModels.length && allModelIds.length === selectedModels.length,
    [selectedModels.length, allModelIds.length]
  );

  const handleCheckAll = (event) => {
    const isChecked = event.target.checked;
    if (!isChecked) {
      setSelectedModels([]);
    } else {
      setSelectedModels(allModelIds);
    }
  };

  const handleCheck = (event) => {
    const value = event.target.id;

    setSelectedModels((prevSelectedModels) => {
      if (prevSelectedModels.includes(value)) {
        return prevSelectedModels.filter((v) => v !== value);
      } else {
        return [...prevSelectedModels, value];
      }
    });
  };

  if (props.fetching) {
    return <Waiter size="large" />;
  }

  if (props.result.length === 0) {
    return (
      <div className="search-result-table__no-data">Данные не найдены</div>
    );
  }

  const headers = props.columns.map((el, i) => {
    return (
      <th className="search-result-table__th" key={i}>
        {el.name}
      </th>
    );
  });

  headers.unshift(
    <th className="search-result-table__th" key={headers.length}>
      <Checkbox
        code={"checkAll"}
        onChange={handleCheckAll}
        checked={isCheckedAll}
      />
    </th>
  );

  headers.push(
    <th className="search-result-table__th" key={headers.length}></th>
  );

  const rows = props.result.map((row, i) => {
    const darkStr = i % 2 === 0 ? "" : " search-result-table__td_dark";
    const hiddenClass = row.hidden ? " hidden" : "";
    const rowContent = [];

    rowContent.push(
      <td
        key={`checkbox_${row.modelComplectCode}`}
        className={"search-result-table__td" + hiddenClass + darkStr}
      >
        <div>
          <Checkbox
            code={row.modelComplectCode}
            onChange={handleCheck}
            checked={selectedModels.includes(row.modelComplectCode)}
          />
        </div>
      </td>
    );

    props.columns.map((col, j) => {
      if (col.code === "years") {
        rowContent.push(
          <td
            key={j}
            className={"search-result-table__td" + hiddenClass + darkStr}
          >
            {(row.yearStart !== undefined ? row.yearStart : "") +
              "-" +
              (row.yearEnd !== undefined ? row.yearEnd : "")}
          </td>
        );
      } else {
        rowContent.push(
          <td
            key={j}
            className={"search-result-table__td" + hiddenClass + darkStr}
          >
            {row[col.code]}
          </td>
        );
      }
    });

    rowContent.push(
      <td
        key={"activateProduct"}
        className={"search-result-table__td" + hiddenClass + darkStr}
      >
        {/* <Link to={'/search_result'}> */}
        <div
          className="search-result-table__row-button"
          data-id={row["modelComplectCode"]}
          onClick={activateProduct}
        >
          <div className="search-result-table__row-button-image"></div>
        </div>

        {/* </Link> */}
      </td>
    );
    return <tr key={i}>{rowContent}</tr>;
  });

  return (
    <div className="search-result">
      <div className="search-result-table-wrapper">
        <table className="search-result-table">
          <thead>
            <tr>{headers}</tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>

      <div className="search-result-table-controls__wrapper">
        <div className="search-result-table-controls">
          <div className="scroll-to-top-button scroll-to-top-button_hide"></div>
          <div className="controls__central-area">
            {props.hasMore && (
              <div className="more-results" onClick={onLoadMore}>
                Показать еще результаты
              </div>
            )}
          </div>
          <div
            className="scroll-to-top-button"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <div className="scroll-to-top-button__img"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultForm;
