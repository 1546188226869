import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tree } from "antd";
import { API } from "./api";
const { DirectoryTree } = Tree; //чтобы при клике на строку, а не только на стрелку раскрывалось

const getChildCodePrefix = (key, level) => {
  switch (level) {
    case 0:
      return `model_`;
    case 1:
      return `generation_`;
    case 2:
      return `complect_`;
    case 3:
      return `component_`;
    default:
      return "";
  }
};

const getCurrentCodePrefix = (key, level) => {
  switch (level) {
    case 1:
      return `model_`;
    case 2:
      return `generation_`;
    case 3:
      return `complect_`;
    case 4:
      return `component_`;
    default:
      return "";
  }
};

const updateTreeData = (list, key, keyPrefix, children, level) =>
  list.map((node) => {
    if (node.code === key) {
      const updatedNode = {
        ...node,
        children: children,
      };

      if (!updatedNode.children.length) {
        delete updatedNode.children;
      }

      return updatedNode;
    }

    if (node.children?.length) {
      return {
        ...node,
        children: updateTreeData(
          node.children,
          key,
          keyPrefix,
          children,
          level
        ),
      };
    }
    return node;
  });

export const MarkTreeSearch = ({ productCode, marksForProduct, lang }) => {
  const navigate = useNavigate();
  const [treeData, setTreeData] = useState(marksForProduct);

  const getLevel = (key, data, level = 0) => {
    for (const node of data) {
      if (node.code === key) {
        return level;
      }
      if (node.children) {
        const childLevel = getLevel(key, node.children, level + 1);
        if (childLevel >= 0) {
          return childLevel;
        }
      }
    }
    return -1;
  };

  const getChildrenData = async (key, keyPrefix, lang, productCode, level) => {
    switch (level) {
      case 0:
        return await API.getModelsByProductAndMark({
          lang: lang,
          productCode: productCode,
          markCode: key.replace(keyPrefix, ""),
        });
      case 1:
        return await API.getModelGenerationsByProductAndModel({
          lang: lang,
          productCode: productCode,
          modelCode: key.replace(keyPrefix, ""),
        });
      case 2:
        return await API.getModelComplectsByProductAndGeneration({
          lang: lang,
          productCode: productCode,
          modelGenerationCode: key.replace(keyPrefix, ""),
        });
      case 3:
        return await API.getComponentsByProductAndModelComplect({
          lang: lang,
          productCode: productCode,
          modelComplectCode: key.replace(keyPrefix, ""),
        });
      default:
        return [];
    }
  };

  const onLoadData = ({ key, pos, children }) =>
    new Promise(async (resolve) => {
      if (children) {
        resolve();
        return;
      }

      const level = getLevel(key, treeData);
      const childCodePrefix = getChildCodePrefix(key, level);
      const currentCodePrefix = getCurrentCodePrefix(key, level);

      const childrenData = await getChildrenData(
        key,
        currentCodePrefix,
        lang,
        productCode,
        level
      );

      const getYearsInterval = (item) => {
        const yearIntervalParts = [
          item?.startYear
            ? item.startYear && !item.endYear
              ? `(${item.startYear})`
              : `(${item.startYear}`
            : undefined,
          item?.endYear && `- ${item.endYear})`,
        ].filter(Boolean);
        return yearIntervalParts.join(" ");
      };

      const createName = (item, level) => {
        switch (level) {
          case 0:
            return `${item.name || ""} ${getYearsInterval(item)} (модель)`;
          case 1:
            return `${item.name || ""} ${getYearsInterval(item)} (поколение)`;
          case 2:
            return `${item.name || ""} ${getYearsInterval(item)} (комплект)`;
          case 3:
            return `${item.componentType} ${item.name || ""} (узел)`;
          default:
            return item.name;
        }
      };

      const updatedChildrenKeys = childrenData.map((item) => {
        return {
          ...item,
          code: `${childCodePrefix}${item.code}`,
          originalCode: item.code,
          name: createName(item, level),
        };
      });

      setTreeData((origin) =>
        updateTreeData(origin, key, childCodePrefix, updatedChildrenKeys, level)
      );
      resolve();
    });

  const onSelect = (keys, info) => {
    const componentCode = info.node.originalCode;
    const modelCode = info.node?.modelComplectCode;

    if (componentCode && modelCode) {
      const url =
        "/model-complect/" + modelCode + "/component/" + componentCode;
      navigate(url, {
        state: { actionType: "edit", componentCode: componentCode },
      });
    }
  };

  return (
    <DirectoryTree
      onSelect={onSelect}
      loadData={onLoadData}
      treeData={treeData}
      fieldNames={{ key: "code", title: "name" }}
      showIcon={false}
    />
  );
};
