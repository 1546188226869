import { fetchFromApi } from "../service";

export function makeModelComplectsReport(modelComplectCodes, callback) {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;

    const response = await fetchFromApi(
      "api/v1/xls/model-complect-multiple/all/products",
      "POST",
      {
        body: {
          lang: locale,
          modelComplectCodes: modelComplectCodes,
        },
      }
    );

    const file = await response.blob();
    if (callback) {
      callback(file);
    }
  };
}

export function makeModelComplectReport(code, callback) {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;

    const response = await fetchFromApi(
      `api/v1/xls/model-complect/all/products/${code}/locale/${locale}`,
      "POST",
      {
        body: {
          lang: locale,
          code,
        },
      }
    );

    const file = await response.blob();
    if (callback) {
      callback(file);
    }
  };
}
