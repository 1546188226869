import React, { useState } from "react";

import { Table } from "antd";
import "./statisticTable.scss";

const columns = [
  { title: "Код", dataIndex: "modelComplectCode" },
  { title: "Марка", dataIndex: "markName" },
  { title: "Модель", dataIndex: "modelName" },
  { title: "Поколение", dataIndex: "modelGenerationName" },
  { title: "Модификация", dataIndex: "modelComplectName" },
  { title: "Узел", dataIndex: "componentName" },
];

export const StatisticTable = ({ data, setSelectedModification }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
  };

  const handleSelect = (record) => {
    if (record?.modelComplectCode) {
      setSelectedRowKeys(record.key);
      setSelectedModification(record.modelComplectCode);
    } else {
      setSelectedRowKeys([]);
      setSelectedModification(null);
    }
  };

  return (
    <div className="statistic-table-wrapper">
      <Table
        columns={columns}
        dataSource={data.marks}
        onRow={(record) => {
          return {
            onClick: (event) => {
              handleSelect(record);
            },
          };
        }}
        rowClassName={(record) =>
          selectedRowKeys?.includes(record.key) ? "ant-table-row-selected" : ""
        }
      />
    </div>
  );
};
