import {
  RESET_COMPONENT_STATE,
  SET_COMPONENT,
  SET_COMPONENT_STATUS,
  UPDATE_COMPONENT_CHANGED_FIELDS,
  UPDATE_COMPONENT_FIELD,
} from "../actions/sync/component";

const initialState = {
  id: null,
  code: "",
  name: null,
  hidden: false,
  modelComplectCode: null,
  componentGroupCode: null,
  componentTypeCode: null,
  startYear: null,
  endYear: null,
  description: null,
  componentFuelTypeCode: null,
  componentGearTypeCode: null,
  componentTurboTypeCode: null,
  componentEngineBuildCode: null,
  componentCoolTypeCode: null,
  producerCode: null,
  engineCap: null,
  fillVolume: null,
  fillVolumeType: '1',
  fillServiceVolumeType: '1',
  serviceVolume: null,
  powerHp: null,
  powerRpm: null,
  valveCnt: null,
  gearFwCnt: null,
  gearRevCnt: null,
  cilinderCap: null,
  cilinderCnt: null,
  stroke: null,
  changedFields: [],
  status: "",
};

function component(state = initialState, action) {
  switch (action.type) {
    case SET_COMPONENT:
      const newState = Object.assign({}, state, action.payload);
      if (newState.fillVolume >= 1000) {
        newState.fillVolumeType = '2';
        newState.fillVolume = newState.fillVolume / 1000;
      }
      if (newState.serviceVolume >= 1000) {
        newState.fillServiceVolumeType = '2';
        newState.serviceVolume = newState.serviceVolume / 1000;
      }
      return newState;
    case UPDATE_COMPONENT_FIELD:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_COMPONENT_CHANGED_FIELDS:
      if (action.payload === "componentGroupCode") {
        return state;
      }

      const isFieldAlreadyMarkedForUpdate = state.changedFields.includes(
        action.payload
      );

      return {
        ...state,
        changedFields: isFieldAlreadyMarkedForUpdate
          ? [...state.changedFields]
          : [...state.changedFields, action.payload],
      };
    case SET_COMPONENT_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case RESET_COMPONENT_STATE:
      return initialState;
    default:
      return state;
  }
}

export default component;
