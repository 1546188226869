import { serverErrorMesasages } from '../../../utils/getErrorMessage';
import HttpError from '../../../utils/httpError';
import { setActiveMenuItem } from '../../reducers/modelComplectMenu';
import { fetchFromApi } from '../service';
import {
  reciveComponentInfo, reciveConditionInfo,
  resetComponent,
  setComponent,
  setComponentStatus,
} from '../sync/component';
import { resetCurrentPageFormState } from '../sync/currentPageFormState';
import {
  initNotificationMessage,
  showNotificationMessage,
} from '../sync/notificationMessage';
import { getComponentTypeList } from './componentType';
import { getModelComplectMenu } from './modelComplectMenu';
import { getProducerList } from './producer';

export function getComponent(code) {
  return async (dispatch) => {
    try {
      dispatch(resetComponent());
      dispatch(setComponentStatus('loading'));
      const response = await fetchFromApi('api/v1/component', 'GET', {
        id: code
      });

      if (!response.ok) {
        throw new HttpError(response.status, response.statusText);
      }

      const data = await response.json();

      await dispatch(
        getComponentTypeList(data.componentGroupCode, data.componentTypeCode)
      );
      await dispatch(getProducerList(data.producerCode));
      dispatch(setComponent({ ...data, changedFields: [] }));
      dispatch(setComponentStatus());
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }

      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          'danger'
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setComponentStatus());
    }
  };
}

export function putComponent(component) {
  return async (dispatch, getState) => {
    const state = getState();
    // const component = state.component;
    const pathname = state.currentPath.path;
    const body = {...component}
    if (body.fillVolumeType === '2') {
      body.fillVolume = body.fillVolume * 1000;
    }
    if (body.fillServiceVolumeType === '2') {
      body.serviceVolume = body.serviceVolume * 1000;
    }

    delete body.fillVolumeType;
    delete body.fillServiceVolumeType;

    try {
      dispatch(setComponentStatus('update'));
      const response = await fetchFromApi('api/v1/component', 'PUT', {
        body: body,
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getModelComplectMenu(body.modelComplectCode));
      dispatch(setActiveMenuItem(pathname));
      dispatch(setComponentStatus());
      dispatch(resetCurrentPageFormState());

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          'danger'
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setComponentStatus());

      return false;
    }
  };
}

export function deleteComponentFromServer(id, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const modelComplectCode = state.modelComplectMenu.modelComplectCode;
    const body = {
      code: id,
      tableName: 'component',
    };

    try {
      dispatch(setComponentStatus('loading'));
      const response = await fetchFromApi('api/v1/dict/record', 'DELETE', {
        body
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(resetCurrentPageFormState());
      await dispatch(getModelComplectMenu(modelComplectCode));
      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || '';

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          'danger'
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setComponentStatus());
    }
  };
}

export function getComponentInfo(code) {
  return (dispatch) => {
    fetchFromApi("api/v1/component/" + code,"GET",{})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error('do not recive component info');
      })
      .then((res) => {
        dispatch(reciveComponentInfo(res));
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function getConditionInfo(code) {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.auth.jwt;

    fetchFromApi("api/v1/condition/" + code,"GET",{token})
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          throw new Error('do not recive component info');
        })
        .then((res) => {
          dispatch(reciveConditionInfo(res));
        })
        .catch((e) => {
          console.log(e);
        });
  };
}


export function copyComponent(originalCodes, toModelComplectCode) { 
  return async (dispatch) => {
  const body = {
    "originalCodes": originalCodes,
    "toModelComplectCode": toModelComplectCode
  }

  try {
    const response = await fetchFromApi("api/v1/component/copy", "POST", {
      body:body,
    });

    const data = await response.json();
    
    if (data?.result === 'SUCCESS') {
      dispatch(
        initNotificationMessage(
          "Сообщение",
          "Узел успешно скопирован",
          "success"
        )
      );
      dispatch(showNotificationMessage());
      return  data?.componentCopyResults[0]?.code
    }
  
    return  data?.componentCopyResults[0]?.code
  } catch (error) {
    let errorMessage = serverErrorMesasages[error.status];

    if (error.body) {
      errorMessage = errorMessage[error.body.msgCode];
    }
    await dispatch(
      initNotificationMessage(
        `${error.status} ${error.message}`,
        errorMessage,
        'danger'
      )
    );

    dispatch(showNotificationMessage());
    dispatch(setComponentStatus());

    return false;
  }
  }
};
